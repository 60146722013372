<template>
<div class="content-wrapper">
  <div class="pt-0 content" v-if="isList">
    <div class="card mt-3">
      <div class="card-header bg-white">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="form-row align-items-center">
              <div class="col-md-10">
                <h5 class="card-title font-weight-semibold">{{ pageTitle }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          
          <div class="col-lg-auto">
            <div class="form-group mb-0">
              <button @click="apiGet()" data-toggle="modal" data-target="#FilterField" data-popup="tooltip"
                class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.left><i class="icon-spinner11"></i></button>
            </div>
          </div>
          <div class="col-lg-auto">
            <div class="input-group">
              <div class="input-group mb-3">
                <datepicker input-class="form-control transparent"  @input="doFill" placeholder="Tanggal Pemeriksaan"
                  class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="filter.tanggal">
                </datepicker>
                <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <b-form-group class="mb-0">
                <v-select placeholder="Pilih Dokter" :disabled="user.levelId == uDokterFisio || user.levelId == uDokter" @input="doFill" v-model="filter.dokter" :options="mDokter" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
            </b-form-group>
          </div>
        </div>
      </div>
      
      <template v-if="filter.dokter && filter.tanggal">
        <div class="table-responsive mb-3">
          <table class="table table-bordered table-striped  table-sm patient-table text-uppercase table-sm">
            <thead>
              <tr class="table-info">
                <th colspan="99">{{dataDokter.bu_full_name}} - {{dataDokter.mpo_name}}
                </th>
              </tr>
              <tr>
                <th>No</th>
                <th>Tgl</th>
                <th>Jam Praktek</th>
                <th>Jam Mulai</th>
                <th>Hasil</th>
                <th>Nilai Kepatuhan</th>
                <th>Waktu Panggil Pasien</th>
                <th>Waktu Rata-rata Periksa Pasien</th>
                <th>Total Pasien Dilayani</th>
                <th>Waktu Periksa Pasien Pertama</th>
                <th>Aksi</th>
              </tr>
            </thead>

            <template v-if="(dataResume||[]).length">
            <tbody>
              <template v-for="(v,k) in (dataResume||[])">
                <tr :class="(v.dataAktivitas.is_terlambat == 'Y' && !v.alej_alasan) ? 'table-danger' : ''" :key="k">
                  <td>{{k+1}}</td>
                  <td>{{v.alej_date | moment("DD MMMM YYYY")}}</td>
                  <td>{{v.alej_jam_praktek_mulai}} - {{v.alej_jam_praktek_selesai}}</td>
                  <td>{{v.alej_jam_mulai||"-"}}</td>
                  <td>
                    <template v-if="v.alej_jam_mulai && v.alej_is_dokter_cuti != 'Y'">
                    <span :style="`color:${v.dataAktivitas.color}`" class="font-weight-semibold">{{v.alej_waktu_telat}} Menit</span> 
                    </template>
                    <span v-else-if="v.alej_is_dokter_cuti == 'Y'" class="text-secondary font-weight-semibold">
                      Dokter Tidak Hadir/Cuti
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>
                    <template v-if="v.alej_jam_mulai">
                    <span :style="`background-color:${v.dataAktivitas.color}`" class="badge badge-warning">{{v.dataAktivitas.text}}</span>
                    </template>
                    <span v-else> - </span>
                  </td>
                  <td>{{v.alej_waktu_panggil_pertama||"-"}}</td>
                  <td>
                    <span v-if="v.alej_waktu_rata_rata_panggil">
                    {{v.alej_waktu_rata_rata_panggil == '0' ? "Kurang dari 1 ": v.alej_waktu_rata_rata_panggil}} Menit
                    </span>
                    <span v-else> - </span>
                  </td>
                  <td>{{v.alej_total_pasien_dilayani||"-"}}</td>
                  <td>
                    <span v-if="v.alej_waktu_selesai_pasien_pertama_in_minutes">
                    {{v.alej_waktu_selesai_pasien_pertama_in_minutes == '0' ? "Kurang dari 1 ": v.alej_waktu_selesai_pasien_pertama_in_minutes}} Menit
                    </span>
                    <span v-else> - </span>
                    </td>
                  <td>
                    <template v-if="v.dataAktivitas.is_terlambat == 'Y' && moduleRole('isi_alasan')">
                      <a href="javascript:;" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.left :title="v.alej_alasan ? 'Edit Alasan' : 'Isi Alasan'" @click="isiAlasan(v)">
                        <i class="icon-file-text"></i>
                      </a>
                    </template>
                  
                    <a href="javascript:;" v-if="v.alej_alasan && moduleRole('view_alasan')" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800 mt-1" v-b-tooltip.hover.left :title="'Lihat Alasan'" @click="viewAlasan(v)">
                      <i class="icon-eye"></i>
                    </a>
                    
                    <a href="javascript:;" v-if="v.alej_is_dokter_cuti != 'Y' && moduleRole('isi_alasan')" class="btn btn-icon rounded-round btn-sm alpha-warning border-warning text-warning-800 mt-1" v-b-tooltip.hover.left :title="'Dokter Cuti / Tidak Hadir'" @click="isiAlasan(v,true)">
                      <i class="icon-notification2"></i>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="99"><span class="font-weight-semibold">Rata-rata waktu periksa pasien total: {{medianData == "0" ? "Kurang dari 1": medianData}} menit</span></th>
              </tr>
            </tfoot>
            </template>

            <template v-else>
            <tbody>
              <tr>
                <td colspan="99" class="text-center">Tidak ada Data</td>
              </tr>
            </tbody>
            </template>
          </table>
        </div>
      </template>
      <template v-else>
        <div class="row">
          <div class="col-12">
            <h5 class="ml-2 mb-3">Pilih Terlebih Dahulu Tanggal dan Dokter</h5>
          </div>
        </div>
      </template>
    </div>

    <validation-observer ref="VFormAlasanTerlambat">
      <b-modal v-model="openAlasan" :title="'Isi Alasan'" size="sm" @ok.prevent="submitAlasanTerlambat">
        <b-form-textarea v-model="rowEdit.alej_alasan" rows="5" class="form-control" />
        <VValidate name="Alasan" v-model="rowEdit.alej_alasan" :rules="{required : 1, min: 3, max: 1500}" />
      </b-modal>
    </validation-observer>

    <b-modal v-model="openViewAlasan" size="sm" :title="'Alasan'" ok-only ok-title="Tutup">
      <b-row>
        <b-col lg="12">
          <pre class="pre-input">{{rowEdit.alej_alasan||"-"}}</pre>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'

import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
const moment = require('moment')
const _ = global._
import $ from 'jquery'

export default {
  extends: GlobalVue,
  components: {
    Datepicker
  },
  data() {
    return {
      dataDokter: {},
      mDokter: [],
      dataResume: [],
      mFlagAktivitasJadwal: [],
      medianData: 0,
      rowEdit: {},
      openAlasan: false,
      openViewAlasan: false
    }
  },
  methods: {
    viewAlasan(row){
      this.rowEdit = row
      this.openViewAlasan = true
    },
    submitAlasanTerlambat(){
      this.$refs['VFormAlasanTerlambat'].validate().then(success => {
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if(result.value){
              
              this.$swal({
                html: 'Menyimpan data...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    this.$swal.showLoading()
                }
              })

              let data = this.rowEdit
              data.type = 'isi-alasan'

              Gen.apiRest(
                "/do/" + 'RoLaporanEfektivitasJadwal', {
                  data: data 
                },
                "POST"
              ).then(res => {
                // this.$parent.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then(result => {
                  if (result.value) {
                    this.openAlasan = false
                    this.getNotificationEfektivitas()
                    this.apiGet()
                  }
                })
              })
            }
         })  
        }
      })
      console.log("submit alasan terlambat")
    },
    isiAlasan(row,isCuti=null){
      this.rowEdit = row
      this.rowEdit.isCuti = isCuti
      this.openAlasan = true
    },
    doFill(){
      this.filter.tanggal = moment(this.filter.tanggal).format("YYYY-MM-DD")
      this.doFilter()
    },

    getNotificationEfektivitas(){
      let data = {
        type: "get-notif-laporan-dokter"
      }
      Gen.apiRest(
        "/do/" + 'Notification', {
          data: data
        },
        "POST"
      ).then(res => {
        // pasien pulang yah
        $("#laporan-efektivitas-notif").remove()
        if(res.data.notifLaporanDokter){
          $("#RoLaporanEfektivitasJadwal a").append(`<span title="Laporan Dokter Terlambat" id="laporan-efektivitas-notif" class="badge badge-danger ml-auto">
          ${res.data.notifLaporanDokter}
          </span>`)
        }
      })
    },
  },
  mounted() {
    this.filter.tanggal = moment().format("YYYY-MM-DD")
    if(this.user.levelId == this.uDokterFisio || this.user.levelId == this.uDokter){
      this.filter.dokter = this.user.id
      this.doFill()
    }else{
      this.apiGet()
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}

</script>